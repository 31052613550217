@import url("https://fonts.googleapis.com/css2?family=Arial:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: "Arial", sans-serif;

  margin: auto auto;
  padding: 10px;
  min-height: 100vh;
  /* minimal 3 color  gradient  */
  background: linear-gradient(
    45deg,
    #88ebee 0%,
    #42bec7 33%,
    #9cf5b2 33%,
    #87e99c 66%,
    #ffc5c5 66%,
    #c96161 100%
  );
  background-size: cover;
  background-position: center;
}

.main {
  background-color: rgba(240, 240, 240, 0.67);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.inputArea {
  margin-bottom: 20px;
  max-width: 580px;
  width: 100%;
}

h2 {
  margin: 0 0 10px;
  font-size: 18px;
  color: #333;
  text-align: center;
}

textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  resize: vertical;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #0056b3;
}

.singleData {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

label {
  flex: 1;
  margin-right: 10px;
}

input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.transactions {
  flex: 2;
}

.copied {
  background-color: #34af23 !important;
  color: #fff !important;
}

.text-center {
  text-align: center;
}

.outputArea {
  margin-top: 20px;
}

p {
  margin-bottom: 10px;
}

b {
  font-weight: bold;
}

.TelegramShareButton {
  display: inline-block;
  margin-top: 10px;
}

@media (max-width: 600px) {
  body {
    padding: 0;
  }
  texarea {
    height: 100px;
  }
  .main {
    box-shadow: none;
    width: 95%;
    margin: 20px auto;
  }
  .inputArea {
    max-width: 80%;
    margin: 0 auto;
  }
  .outputArea {
    margin-top: 0;
  }
}
